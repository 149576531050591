import env from 'env';
import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Header } from 'semantic-ui-react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Organization, SpotInterface } from 'types';
import { isActiveTab } from 'utils/helpers';
import { AuthorizedView, Roles } from '../../Utilities/AuthorizedView';
import AGBadge from 'components/AGBadge/AGBadge';
import { hasAccessToVisibilityPage } from 'utils/spotConfig';

type SpotMenuProps = {
    navigateToTab: (tabUrl: string) => void;
    selectedSpotInfo: SpotInterface | null;
    spots: SpotInterface[];
    organization: Organization;
};

const SpotMenu = ({ navigateToTab, spots, organization, selectedSpotInfo }: SpotMenuProps): JSX.Element => {
    let routeToMatch = '/organization/:orgId/spots/:spotId/:path';
    let isSpotPkInUrl = true;
    if (selectedSpotInfo === null) {
        routeToMatch = '/organization/:orgId/:path';
        isSpotPkInUrl = false;
        selectedSpotInfo = spots[0];
    }
    const [spotRefForMenu, setSpotRefForMenu] = useState(selectedSpotInfo);
    useEffect(() => {
        if (selectedSpotInfo) setSpotRefForMenu(selectedSpotInfo);
    }, [selectedSpotInfo]);
    const match = useRouteMatch<{
        orgId: string;
        spotId: string;
        path: string;
    }>(routeToMatch);
    const { search } = useLocation();
    const hasRentals = !!spotRefForMenu.price_hourly || !!spotRefForMenu.price_monthly || !!spotRefForMenu.price_daily;

    if (!match || spots.length === 0) {
        return <></>;
    }

    const navigateToPath = (path: string) => () =>
        navigateToTab(`/organization/${organization.pk}/spots/${spotRefForMenu.pk}${path}`);
    const onChangeSpot = (spotId: string) => {
        const spot = spots.find((s) => s.pk === parseInt(spotId));
        if (spot && !isSpotPkInUrl) {
            setSpotRefForMenu(spot);
        } else if (!match.params.path) {
            navigateToTab(`/organization/${organization.pk}/spots/${spotId || spotRefForMenu.pk}/detail`);
        } else {
            // keep all search parameters except page - that could throw a 404.
            const query = new URLSearchParams(search);
            query.delete('page');
            navigateToTab(
                `/organization/${organization.pk}/spots/${spotId || spotRefForMenu.pk}/${
                    match.params.path
                }?${query.toString()}`
            );
        }
    };

    function renderIcon() {
        const hasMultipleSpots = spots.length > 1;
        return hasMultipleSpots ? 'dropdown' : null;
    }

    const dropdownOptions = spots.map((spot: SpotInterface) => ({ text: spot.name, value: spot.pk, key: spot.pk }));

    return (
        <>
            <Menu.Item name="Property" header />
            {spots.length > 1 ? (
                <Header as="h5">
                    <Dropdown
                        icon={renderIcon()}
                        selection
                        fluid
                        search
                        value={spotRefForMenu.pk}
                        options={dropdownOptions}
                        onChange={(e, data) => {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            onChangeSpot(data.value!.toString());
                        }}
                    />
                </Header>
            ) : (
                ''
            )}
            {
                <AuthorizedView allowed={[Roles.Superuser]}>
                    <Menu.Item name="Rates" active={isActiveTab('rates')} onClick={navigateToPath('/rates')} />
                </AuthorizedView>
            }
            {hasRentals && (
                <AuthorizedView allowed={[Roles.Admin, Roles.Employee]}>
                    {spotRefForMenu.spot_config.show_timeline && (
                        <Menu.Item
                            name="Timeline"
                            active={isActiveTab('timeline')}
                            onClick={navigateToPath('/timeline')}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            Timeline
                            <AGBadge text="NEW" />
                        </Menu.Item>
                    )}
                    <Menu.Item
                        name="Event Calendar"
                        active={isActiveTab('events')}
                        onClick={navigateToPath('/events')}
                    />
                </AuthorizedView>
            )}
            <AuthorizedView allowed={[Roles.Admin, Roles.Employee]}>
                <Menu.Item name="Visitors" active={isActiveTab('visitors')} onClick={navigateToPath('/visitors')} />
                <Menu.Item name="Validation Codes" active={isActiveTab('codes')} onClick={navigateToPath('/codes')} />
            </AuthorizedView>

            {hasRentals && (
                <AuthorizedView allowed={[Roles.Admin, Roles.Employee]}>
                    <Menu.Item
                        name="Discount Codes"
                        active={isActiveTab('promos')}
                        onClick={navigateToPath('/promos')}
                    />
                    <Menu.Item
                        name="Waive a Violation"
                        active={isActiveTab('violations')}
                        onClick={navigateToPath('/violations')}
                    />
                </AuthorizedView>
            )}

            <AuthorizedView allowed={[Roles.Admin, Roles.Employee, Roles.Enforcer]}>
                <Menu.Item name="Drivers" active={isActiveTab('drivers')} onClick={navigateToPath('/drivers')} />
            </AuthorizedView>

            {[599, 775, 776].includes(spotRefForMenu.pk) && (
                <AuthorizedView allowed={[Roles.Enforcer, Roles.Admin]}>
                    <Menu.Item name="Enforcement" onClick={navigateToPath('/check-plate')} />
                </AuthorizedView>
            )}
            {spotRefForMenu.classification === 'Airport Lot' && (
                <Menu.Item
                    name="Aggregator Rentals"
                    active={isActiveTab('aggregator-rentals')}
                    onClick={navigateToPath('/aggregator-rentals')}
                />
            )}
            {hasRentals && (
                <>
                    <AuthorizedView allowed={Roles.Admin}>
                        <Menu.Item
                            name="Reports"
                            active={isActiveTab('reports')}
                            onClick={navigateToPath('/reports')}
                        />
                        <Menu.Item name="Graphs" active={isActiveTab('graphs')} onClick={navigateToPath('/graphs')} />
                        {hasAccessToVisibilityPage(spotRefForMenu) && (
                            <Menu.Item
                                name="Visibility"
                                active={isActiveTab('visibility')}
                                onClick={navigateToPath('/visibility')}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                Visibility
                                <AGBadge text="NEW" />
                            </Menu.Item>
                        )}
                    </AuthorizedView>
                    <Menu.Item
                        name="Driver-insights"
                        active={isActiveTab('driver-insights')}
                        onClick={navigateToPath('/driver-insights')}
                    />
                </>
            )}
            {env.API_BASE.includes('demo') && (
                <Menu.Item
                    name="Custom Reports"
                    active={isActiveTab('custom-reports')}
                    onClick={navigateToPath('/custom-reports')}
                />
            )}
        </>
    );
};

export default SpotMenu;
