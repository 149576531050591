import React, { useState } from 'react';
import { Icon, Header, Label, Card, Button } from 'semantic-ui-react';
import amplitude from 'amplitude-js';
import { SpotInterface } from 'types';

interface SpotsProps {
    spots: SpotInterface[];
}

const Advertising: React.FC<SpotsProps> = ({ spots }) => {
    const [linkCopied, setLinkCopied] = useState(false);

    const copyToClipboard = (link: string) => {
        amplitude.getInstance().logEvent('Copied advertising link');
        navigator.clipboard.writeText(link);
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 2000);
    };

    const spotPageLink = (spot: SpotInterface) => spot.webflow_page_url;

    return (
        <div>
            <Header as="h4">
                Place the following links on your website or share them with your community for convenience.
            </Header>
            {linkCopied && (
                <Label style={{ marginBottom: 10 }} color="green">
                    Link copied!
                </Label>
            )}
            {spots.map((spot) => (
                <Card key={spot.pk} fluid>
                    <Card.Content>
                        <Card.Header>{spot.name}</Card.Header>
                        <Card.Meta style={{ paddingTop: 7, paddingBottom: 10 }}>
                            <b>Booking link: </b>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                style={{ color: '#1A73E8', textDecoration: 'underline' }}
                                href={spot.webflow_page_url}
                            >
                                {spot.webflow_page_url}
                            </a>
                        </Card.Meta>

                        <Button
                            icon
                            labelPosition="left"
                            onClick={() => copyToClipboard(spotPageLink(spot))}
                            color="orange"
                        >
                            <Icon name="clipboard" />
                            Copy Link
                        </Button>
                    </Card.Content>
                </Card>
            ))}
        </div>
    );
};

export default Advertising;
