import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import logo from 'assets/AirGarage.svg';
import parkingLot from 'assets/parkingLot.webp';
import LoginForm from 'components/LoginForm/LoginForm';
import { parseQueryParams } from 'utils/helpers';
import styles from './LoginView.module.css';
import { CONFIG } from 'utils/whiteLabel.config';
import Sso from 'components/Sso/Sso';
import LinkLikeButton from 'components/Button/LinkLikeButton';

function LoginView(props: RouteComponentProps) {
    const urlParams = parseQueryParams(props.location.search);
    const [showSso, setShowSSo] = useState(CONFIG.allowSso ? true : false);

    return (
        <div className={styles.container}>
            <div className={styles.loginForm}>
                <img src={logo} alt="AirGarage" height={32} />
                <div>
                    {CONFIG.allowSso && showSso ? <Sso /> : <LoginForm urlParams={urlParams} />}
                    {CONFIG.allowSso && (
                        <LinkLikeButton onClick={() => setShowSSo(!showSso)}>
                            {showSso ? 'Login with phone or email' : 'Login with okta'}
                        </LinkLikeButton>
                    )}
                </div>
            </div>
            <div>
                <img src={parkingLot} alt="" width="100%" height="100%" className={styles.backgroundImage} />
            </div>
        </div>
    );
}

export default withRouter(LoginView);
