// These are the required environment variables, we need to throw an error if they are not set
if (
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_API_BASE') ||
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_WEBPAY_DOMAIN') ||
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_DJANGO_ADMIN') ||
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_DOWNLOAD_DOMAIN') ||
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_SOCKETS_DOMAIN') ||
    !Object.prototype.hasOwnProperty.call(process.env, 'REACT_APP_MAPBOX_ACCESS_TOKEN')
) {
    console.warn(
        `ADD FOLOWING ENVIRONMENT VARIABLES TO .env FILE:
            - REACT_APP_API_BASE
            - REACT_APP_WEBPAY_DOMAIN
            - REACT_APP_DJANGO_ADMIN
            - REACT_APP_DOWNLOAD_DOMAIN
            - REACT_APP_SOCKETS_DOMAIN
            - REACT_APP_MAPBOX_ACCESS_TOKEN
        `
    );
    throw new Error('Missing required environment variable');
}

// These are the optional environment variables, we need set default values if they are not set
const optionalEnvVars = {
    LOGROCKET_APP_ID: process.env.REACT_APP_LOGROCKET_APP_ID || '',
    PACKAGE_VERSION: process.env.REACT_APP_NPM_PACKAGE_VERSION || '0.0.0',
    MINUTES_TO_UPDATE_REPORTS: Number(process.env.REACT_APP_MINUTES_TO_UPDATE_REPORTS) || 10,
    WORKOS_ORG_ID: process.env.REACT_APP_WORKOS_ORG_ID || '',
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID || 'airgarage',
};

const env = {
    API_BASE: process.env.REACT_APP_API_BASE as string,
    WEBPAY_DOMAIN: process.env.REACT_APP_WEBPAY_DOMAIN as string,
    DJANGO_ADMIN: process.env.REACT_APP_DJANGO_ADMIN as string,
    DOWNLOAD_DOMAIN: process.env.REACT_APP_DOWNLOAD_DOMAIN as string,
    SOCKETS_DOMAIN: process.env.REACT_APP_SOCKETS_DOMAIN as string,
    MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
    NODE_ENV: process.env.NODE_ENV,
    ...optionalEnvVars,
};

export default env;
