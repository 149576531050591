import env from 'env';

type ClientIds = 'airgarage' | 'meta';

const APP_CLIENT_IDS: Record<string, ClientIds> = {
    meta: 'meta',
    airgarage: 'airgarage',
};

const CLIENT_ID: ClientIds = (APP_CLIENT_IDS[env.CLIENT_ID] as ClientIds | undefined) || APP_CLIENT_IDS.airgarage;

type ClientConfig = {
    headerText: string;
    allowSso: boolean;
    ssoLink: string;
};

const CLIENTS_CONFIG: Record<ClientIds, ClientConfig> = {
    airgarage: {
        headerText: 'AirGarage',
        allowSso: false,
        ssoLink: '',
    },
    meta: {
        headerText: 'Meta',
        allowSso: true,
        ssoLink: `${env.API_BASE}auth_sso/${env.WORKOS_ORG_ID}/init/?next_url=/`,
    },
};

export const CONFIG: ClientConfig = CLIENTS_CONFIG[CLIENT_ID] || CLIENTS_CONFIG.airgarage;
