import Button from 'components/Button/Button';
import React from 'react';
import { CONFIG } from 'utils/whiteLabel.config';

export default function Sso() {
    return (
        <>
            <h2>Welcome to AirGarage</h2>
            <a href={CONFIG.ssoLink}>
                <Button>
                    <span>Continue with Okta</span>
                </Button>
            </a>
        </>
    );
}
