import React from 'react';
import { Popup } from 'semantic-ui-react';
import { PagedResponse } from 'types/Pagination';
import { SlotInterface, SpotInterface } from 'types';
import { dateFormatter, timeFormatter } from 'utils/helpers';
import { RentalType, anonymousUsername } from 'utils/constants';
import { SLOT_PAGE_SIZE } from 'clients/SlotClient';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import AGTableWrapper from 'components/AGTable/AGTableWrapper';
import Pagination from 'components/Pagination/Pagination';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';
import IconButton from 'components/Button/IconButton';
import PersonOffIcon from 'components/Icons/PersonOffIcon';
import styles from './DailyRentals.module.css';
import { showContactInfoForRentalType } from 'utils/spotConfig';

type DailyRentalsTableProps = {
    paginatedDailyRentals: PagedResponse<SlotInterface>;
    page: string;
    setPage: (page: string) => void;
    editRental: (rental: SlotInterface) => void;
    showContact: boolean;
    spot: SpotInterface;
};

function DailyRentalsTable({
    paginatedDailyRentals,
    page,
    setPage,
    editRental,
    showContact,
    spot,
}: DailyRentalsTableProps) {
    const { results: rentals } = paginatedDailyRentals;
    const totalPages = Math.ceil(paginatedDailyRentals.count / SLOT_PAGE_SIZE);

    function getContactInfo(rental: SlotInterface): string | null {
        if (showContactInfoForRentalType(spot, rental.rental_type)) {
            const email = rental.renter_email;
            return email && email !== anonymousUsername ? email : rental.renter_phone ? rental.renter_phone : 'n/a';
        } else return null;
    }

    function getDuration(rental: SlotInterface): string {
        let duration = dateFormatter(rental.start_date);

        switch (rental.rental_type) {
            case RentalType.Daily:
                duration += ` - ${rental.end_date ? dateFormatter(rental.end_date) : 'until they cancel'}`;
                break;
            case RentalType.Flat:
                duration += ` ${timeFormatter(rental.start_date)} - 11:59 PM`;
                break;
            case RentalType.Hourly:
                duration += ` ${timeFormatter(rental.start_date)} - Not ended`;
        }

        return duration;
    }

    return (
        <AGTableWrapper>
            <thead>
                <tr>
                    <th>License Plate</th>
                    {showContact && <th>Contact</th>}
                    <th>Duration</th>
                    <th>Rental type</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {rentals.length > 0 ? (
                    rentals.map((rental) => {
                        const rentalCars = rental.cars_detail
                            .map((car: any) => `${car.plate}${car.state ? ` - ${car.state}` : ''}`)
                            .join(', ');
                        const contact = getContactInfo(rental);

                        return (
                            <tr key={rental.pk}>
                                <td>{rentalCars}</td>
                                {showContact && (
                                    <td>
                                        {contact ? (
                                            contact
                                        ) : (
                                            <Popup trigger={<PersonOffIcon />} on="hover" inverted wide>
                                                Driver contact info is available upon request for this rate
                                            </Popup>
                                        )}
                                    </td>
                                )}
                                <td>{getDuration(rental)}</td>
                                <td className={styles.rentalType}>
                                    {rental.rental_type}
                                    {Boolean(rental.auto_started) && <i> - Autostarted</i>}
                                </td>
                                <td>
                                    <AuthorizedView allowed={[Roles.Superuser, Roles.Admin]}>
                                        <IconButton onClick={() => editRental(rental)}>
                                            <EditIcon width={16} fill="#808080" />
                                        </IconButton>
                                    </AuthorizedView>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={showContact ? 5 : 4}>Transient drivers' information will show up here</td>
                    </tr>
                )}
            </tbody>
            {totalPages > 1 && (
                <tfoot>
                    <tr>
                        <td colSpan={6}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Pagination currentPage={Number(page)} onPageChange={setPage} totalPages={totalPages} />
                            </div>
                        </td>
                    </tr>
                </tfoot>
            )}
        </AGTableWrapper>
    );
}

export default DailyRentalsTable;
