import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import AGDatePicker from 'components/AGDatePicker/AGDatePicker';
import AGDropdown from 'components/AGDropdown/AGDropdown';
import Button from 'components/Button/Button';
import React, { useState } from 'react';
import { getFormattedDate } from 'utils/helpers';
import styles from './IncomeStatement.module.css';
import { getPreviousMonth } from './utils';

type DateSelectorProps = {
    startDate: Date;
    endDate: Date;
    onSave: (startDate: Date, endDate: Date) => void;
    dropdownWidth?: string;
    isInModal?: boolean;
};

const DateSelector = (props: DateSelectorProps) => {
    const [openDropdown, toggleOpenDropdown] = useState(false);
    const [startDate, setStartDate] = useState<Date | undefined>(props.startDate);
    const [endDate, setEndDate] = useState<Date | undefined>(props.endDate);

    const today = new Date();

    function handleSave(event: React.MouseEvent) {
        event.nativeEvent.preventDefault();
        event.preventDefault();
        if (startDate && endDate) {
            props.onSave(startDate, endDate);
        }
        toggleOpenDropdown(false);
    }

    function selectCurrentMonth() {
        setStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
        setEndDate(today);
    }

    function selectPreviousMonth() {
        const year = today.getFullYear();
        const month = today.getMonth() - 1;
        const lastDayInMonth = new Date(year, month + 1, 0).getDate();
        setStartDate(new Date(year, month, 1));
        setEndDate(new Date(year, month, lastDayInMonth));
    }

    function selectYearToDate() {
        setStartDate(new Date(today.getFullYear(), 0, 1));
        setEndDate(today);
    }

    function selectPreviousYear() {
        const year = today.getFullYear() - 1;
        const lastDayInYear = new Date(today.getFullYear(), 1, 0).getDate();
        setStartDate(new Date(year, 0, 1));
        setEndDate(new Date(year, 11, lastDayInYear));
    }

    function handleToggleOpenDropdown(state: boolean) {
        // When dropdown is closed but "Apply" button was not clicked:
        if (!state) {
            setStartDate(props.startDate);
            setEndDate(props.endDate);
            toggleOpenDropdown(false);
        } else toggleOpenDropdown(true);
    }

    return (
        <AGDropdown
            text={`${startDate ? getFormattedDate(startDate) : ''} - ${endDate ? getFormattedDate(endDate) : ''}`}
            icon={<Calendar className={styles.calendarIcon} />}
            open={openDropdown}
            toggleOpen={handleToggleOpenDropdown}
            width={props.dropdownWidth || '290px'}
            isInModal={props.isInModal}
        >
            <div className={styles.dateSelector}>
                <AGDatePicker
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                    isRangeMode
                    latestAvailableDate={today}
                />
                <div className={styles.dateSelectorButtons}>
                    <button onClick={selectCurrentMonth}>This month</button>
                    <button onClick={selectPreviousMonth}>{getPreviousMonth(today)}</button>
                    <button onClick={selectYearToDate}>{today.getFullYear()}</button>
                    <button onClick={selectPreviousYear}>{today.getFullYear() - 1}</button>
                </div>

                <Button onClick={handleSave} size="sm" disabled={!startDate || !endDate}>
                    Apply
                </Button>
            </div>
        </AGDropdown>
    );
};

export default DateSelector;
