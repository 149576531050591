import React, { FormEvent, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { loginAction, sendVerificationCodeAction, getProfileAction } from './../../actions/actions';
import useForm from './../../hooks/useForm';
import { isPhone, validateEmail } from 'utils/helpers';
import { shortOptionsForSelect } from 'assets/data/countriesPhoneCodes';
import AGForm from 'components/AGForm/AGForm';
import Label from 'components/AGForm/Inputs/Label/Label';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import AGSimpleSelect from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import Button from 'components/Button/Button';
import CodeInput from 'components/AGForm/Inputs/CodeInput/CodeInput';
import AGMessage from 'components/AGMessage/AGMessage';
import styles from './LoginForm.module.css';

type LoginFormProps = { urlParams: { [key: string]: string } } & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

function LoginForm({
    urlParams,
    getProfile,
    login,
    verificationCodeSent,
    sendVerificationCode,
    successMessage,
    errorMessage,
    loading,
}: LoginFormProps) {
    const { inputs, handleInput, handleValueChange } = useForm({ username: '', phoneCode: '+1', password: '' });

    const [hasOnlyNumbers, disableGetCodeButton] = useMemo(() => {
        const hasOnlyNumbers = isPhone(inputs.username);
        const disableGetCodeButton =
            (!hasOnlyNumbers && !validateEmail(inputs.username)) ||
            (hasOnlyNumbers && inputs.username.length < 4) ||
            (hasOnlyNumbers && inputs.phoneCode === '+1' && inputs.username.length !== 10);

        return [hasOnlyNumbers, disableGetCodeButton];
    }, [inputs.username, inputs.phoneCode]);

    useEffect(() => {
        if (successMessage === 'Logging in...') {
            getProfile();
        }
    }, [successMessage, getProfile]);

    useEffect(() => {
        const { username, code } = urlParams;
        // Login user via code in url params if available
        if (code) {
            login({ username, password: code });
        }
    }, [login, urlParams]);

    function requestCode() {
        if (inputs.password) handleValueChange('password', '');
        const phoneCode = hasOnlyNumbers ? inputs.phoneCode : '';
        const completeUsername = phoneCode + inputs.username;
        sendVerificationCode(completeUsername, phoneCode);
    }

    function submitCode() {
        const completeUsername = (hasOnlyNumbers ? inputs.phoneCode : '') + inputs.username;
        login({ ...inputs, username: completeUsername });
    }

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (!verificationCodeSent) requestCode();
        else submitCode();
    }

    return (
        <div>
            <h2>{verificationCodeSent ? 'Enter the Verification Code' : 'Welcome to AirGarage'}</h2>

            <AGForm onSubmit={handleSubmit}>
                {errorMessage && (
                    <AGMessage title="Error: Unable to log in with the provided credentials" color="error">
                        <p>
                            Having trouble logging in? Please click here to message Customer Support or visit{' '}
                            <a href="https://help.airgarage.com">https://help.airgarage.com/</a>
                        </p>
                    </AGMessage>
                )}

                <div className={styles.inputs} style={errorMessage ? {} : { marginTop: '24px' }}>
                    {!verificationCodeSent ? (
                        <>
                            <div>
                                <Label label="Email or Phone Number" />
                                <div className={styles.usernameField}>
                                    {hasOnlyNumbers && (
                                        <AGSimpleSelect
                                            selected={inputs.phoneCode}
                                            onSelect={(selection) => handleValueChange('phoneCode', selection)}
                                            options={shortOptionsForSelect}
                                            maxHeigh="400px"
                                            width="80px"
                                        />
                                    )}
                                    <div className={styles.usernameInput}>
                                        <TextInput
                                            label=""
                                            onChange={handleInput}
                                            placeholder="Enter your email or phone number"
                                            errors={null}
                                            name="username"
                                            value={inputs.username}
                                        />
                                    </div>
                                </div>
                            </div>

                            <Button onClick={requestCode} disabled={disableGetCodeButton} loading={loading}>
                                Get Verification Code
                            </Button>
                        </>
                    ) : (
                        <>
                            <div>
                                <p>
                                    Didn't receive a verification code?
                                    <a href="# " onClick={requestCode}>
                                        {' '}
                                        Resend to {inputs.username}
                                    </a>
                                </p>
                                <CodeInput
                                    label=""
                                    onCodeChange={(value) => {
                                        handleValueChange('password', value);
                                    }}
                                    inputmode="numeric"
                                    errors={null}
                                    disabled={loading}
                                    codeLength={6}
                                    name="password"
                                    onSubmit={submitCode}
                                />
                            </div>
                            <Button onClick={submitCode} disabled={inputs.password.length !== 6} loading={loading}>
                                Log in
                            </Button>
                        </>
                    )}
                </div>
            </AGForm>
        </div>
    );
}

function mapStateToProps(state: any) {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        loading: state.auth.loading,
        verificationCodeSent: state.auth.verificationCodeSent,
    };
}

const mapDispatchToProps = {
    login: loginAction,
    sendVerificationCode: sendVerificationCodeAction,
    getProfile: getProfileAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
