import { PricingStrategy, RateIntervalOption, RentalType } from 'utils/constants';

interface AddressInterface {
    address_line1: string;
    address_line2: string;
    city: string;
    country: string;
    latitude: string;
    longitude: string;
    state: string;
    zipcode: string;
}

interface ActiveRate {
    unit: RentalType;
    interval: RateIntervalOption;
    display_price: null | string;
    subtext_override: string | null;
}
interface SpaceforceConfig {
    spaceforce_multiplier: number;
    note_bounty_amount: number;
    scan_bounty_amount: number;
    immobilization_fee_amount: number;
    immobilization_bounty_amount: number;
    personal_scan_cooldown_minutes: number;
    note_left_cooldown_minutes: number;
    autostart_bounty_amount: number;
    autostart_fee: number;
}

export enum VisibilityPageSectionEnabling {
    ALL = 'all',
    NONE = 'none',
    CAMERAS = 'cameras',
    STATS = 'stats',
}

export enum ContactInfoPerRentalType {
    HOURLY = 'hourly',
    FLAT = 'flat',
    DAILY = 'daily',
}

type SpotConfig = {
    show_timeline: boolean;
    visibility_page_section: VisibilityPageSectionEnabling;
    contact_info_per_rental_type: ContactInfoPerRentalType[];
};

export interface SpotInterface {
    name: string;
    address: AddressInterface;
    classification: string;
    price?: number;
    price_monthly: number;
    active: boolean;
    photo_url: string;
    quantity: number;
    quantity_monthly: number;
    price_hourly: number;
    price_daily: number;
    daily_max: number;
    daily_min: number | null;
    streetview_url: string;
    spaces_available: Array<object>;
    available: number;
    active_rentals: number;
    monthly_rentals: number;
    closure_today: string | null;
    closure_soon: string | null;
    dynamic_price: number;
    dynamic_max: number;
    dynamic_pricing_enabled: boolean;
    dynamic_pricing_strategy: PricingStrategy;
    display_price: string;
    display_price_daily: string;
    display_price_monthly: string;
    dynamic_daily_price?: number;
    display_daily_min?: string;
    has_cameras?: boolean;
    active_event?: unknown | null;
    free_until_hourly_event?: unknown | null;
    hidden?: boolean;
    display_daily_max: string;
    payment_phone: string;
    visitor_registration_phone: string | null;
    spaceforce_multiplier: number;
    organization: number;
    period_length: string;
    pk: number;
    timezone: string;
    uuid: string;
    active_rate: null | ActiveRate;
    tax_percent: number;
    minimum_booking_length?: number;
    monthly_available?: number;
    monthly_rental_instructions?: string;
    spaceforce_config?: SpaceforceConfig;
    validation_instruction?: unknown | null;
    third_party_id?: unknown | null;
    experiment_name?: string;
    segment_name?: string;
    charge_by_night?: boolean;
    driver_fee_amount?: unknown;
    spot_config: SpotConfig;
    webflow_page_url: string;
}

export type SpotMetadata = Pick<
    SpotInterface,
    'uuid' | 'name' | 'address' | 'organization' | 'pk' | 'timezone' | 'photo_url'
>;
