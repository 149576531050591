import { getStateFromValue } from 'assets/data/countriesAndStates';
import PencilIcon from 'components/Icons/PencilIcon';
import { CountryAbbreviation } from 'constants/states';
import React, { useState } from 'react';
import { ValetSession, ValetSessionStatus } from 'types/ValetSession';
import RowSkeleton from './RowSkeleton';
import StateTag from './StateTag';
import styles from './Table.module.css';

interface ValetTableProps {
    valetRentals: ValetSession[];
    onOpenEditModal: (valetSession: ValetSession) => void;
    loading?: boolean;
}

const Table = ({ valetRentals, onOpenEditModal, loading }: ValetTableProps) => {
    const [selectedRental, setSelectedRental] = useState<string | null>(null);

    function handleRowSelection(valetSession: ValetSession) {
        if (selectedRental === valetSession.uuid) onOpenEditModal(valetSession);
        else setSelectedRental(valetSession.uuid);
    }

    function handleEditButtonClick(valetSession: ValetSession) {
        setSelectedRental(valetSession.uuid);
        onOpenEditModal(valetSession);
    }

    function tableLoader() {
        return Array.from({ length: 15 }).map((v, i) => <RowSkeleton key={i} />);
    }

    return (
        <div className={styles.tblWrapper}>
            <table className={styles.tbl}>
                <thead>
                    <tr>
                        <th>Plate</th>
                        <th>State</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Color</th>
                        <th>Phone</th>
                        <th>Status</th>
                        <th>Notes</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? tableLoader() : null}
                    {!loading &&
                        valetRentals.map((valetSession) => {
                            const car = valetSession.car_details;
                            const carState = car?.state
                                ? getStateFromValue(car.state, car.country || CountryAbbreviation.UnitedStates)
                                : undefined;

                            return (
                                <tr
                                    className={selectedRental === valetSession.uuid ? styles.slctd : ''}
                                    key={valetSession.uuid}
                                    onClick={() => handleRowSelection(valetSession)}
                                >
                                    <td>{car?.plate}</td>
                                    <td>{carState ? `${carState.label}` : ''}</td>
                                    <td>{valetSession.make ? valetSession.make : ''}</td>
                                    <td>{valetSession.model ? valetSession.model : ''}</td>
                                    <td>{valetSession.color ? valetSession.color : ''}</td>
                                    <td>{valetSession.phone}</td>
                                    <td>
                                        <StateTag valetSessionStatus={valetSession.status} />
                                    </td>
                                    <td>
                                        <p className={styles.notes}>{valetSession.notes || ''}</p>
                                    </td>
                                    <td>
                                        {valetSession.status === ValetSessionStatus.Active ? (
                                            <button
                                                className={styles.edt}
                                                onClick={() => handleEditButtonClick(valetSession)}
                                            >
                                                <PencilIcon />
                                            </button>
                                        ) : null}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
