import { ContactInfoPerRentalType, SpotInterface, VisibilityPageSectionEnabling } from 'types';
import { RentalType } from './constants';

export const hasAccessToVisibilityPage = (spot: SpotInterface) =>
    spot.spot_config.visibility_page_section &&
    spot.spot_config.visibility_page_section !== VisibilityPageSectionEnabling.NONE;

export const hasAccessToHeroStats = (spot: SpotInterface) =>
    spot.spot_config.visibility_page_section === VisibilityPageSectionEnabling.STATS ||
    spot.spot_config.visibility_page_section === VisibilityPageSectionEnabling.ALL;

export const hasAccessToDriverLog = (spot: SpotInterface) =>
    spot.spot_config.visibility_page_section === VisibilityPageSectionEnabling.ALL;

// Contact info feature flag
const mapRentalTypeToFeatureFlag: { [key in RentalType]?: ContactInfoPerRentalType } = {
    [RentalType.Daily]: ContactInfoPerRentalType.DAILY,
    [RentalType.Flat]: ContactInfoPerRentalType.FLAT,
    [RentalType.Hourly]: ContactInfoPerRentalType.HOURLY,
};

export function showContactInfoForRentalType(spot: SpotInterface, rentalType: RentalType) {
    return Boolean(
        mapRentalTypeToFeatureFlag[rentalType] &&
            spot.spot_config.contact_info_per_rental_type.includes(
                mapRentalTypeToFeatureFlag[rentalType] as ContactInfoPerRentalType
            )
    );
}

export function showContactInfoForRentalTypeList(spot: SpotInterface, rentalTypes: RentalType[]) {
    for (const rentalType of rentalTypes) {
        if (showContactInfoForRentalType(spot, rentalType)) {
            return true;
        }
    }
    return false;
}
//
